import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../style/colors";
import InterestedInBecoming from "../assets/images/interested-in-becoming.png";
import adminService from "../services/admin.service";

const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Footer = () => {
  const [restaurantName, setRestaurantName] = useState("");
  const [email, setEmail] = useState("");
  const [restaurantNameError, setRestaurantNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleRestaurantNameChange = (e) => {
    setRestaurantName(e.target.value);
    setRestaurantNameError(e.target.value ? "" : "Restaurant name is required");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(e.target.value ? "" : "Email is required");
  };

  const validateEmail = (email) => EmailRegex.test(email);

  const handleRestaurantNameSubmit = async () => {
    if (!restaurantName) {
      setRestaurantNameError("Restaurant name is required");
      return;
    }
    try {
      await adminService.addrestaurantandemail({
        text: restaurantName,
        is_email: false,
      });

      setRestaurantName("");
      setSnackbarMessage("Restaurant name sent successfully!");
      setOpenSnackbar(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleEmailSubmit = async () => {
    if (!email) {
      setEmailError("Email is required");
      return;
    }
    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
      return;
    }

    try {
      await adminService.addrestaurantandemail({
        text: email,
        is_email: true,
      });
      setEmail("");
      setSnackbarMessage("Email sent successfully!");
      setOpenSnackbar(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <Box sx={{ background: "#F7F7F7" }} className="bgib">
        <Container maxWidth="xl">
          <Grid container sx={{ zIndex: "1", position: "relative" }}>
            <Grid
              item
              sm="6"
              xs="12"
              py={{ xs: "40px" }}
              pr={{ sm: "20px", md: "62px" }}
            >
              <Typography
                variant="h2"
                pb="24px"
                color={colors.themeText.textBlack}
              >
                Tell us which <br /> restaurants you would <br /> like added to
                Skip?
              </Typography>
              <Grid container spacing={"12px"}>
                <Grid item sm={9} xs={12}>
                  <TextField
                    value={restaurantName}
                    onChange={handleRestaurantNameChange}
                    placeholder="Enter restaurant name"
                    sx={{
                      backgroundColor: "#FFF",
                      borderRadius: "30px",
                      color: "#0D0D0D",
                      width: "100%",
                      fontSize: "14px",
                      "& input": {
                        py: { md: "14px", xs: "8px" },
                        paddingX: (theme) => theme.spacing(2.3),
                        fontSize: "14px",
                        fontWeight: "500",
                      },
                      "& fieldset": {
                        borderRadius: "30px",
                        border: "1px solid #0D0D0D",
                        color: "#0D0D0D",
                        fontSize: "14px",
                      },
                    }}
                  />
                  {restaurantNameError && (
                    <Typography color="error">{restaurantNameError}</Typography>
                  )}
                </Grid>

                <Grid item lg={3}>
                  <Button
                    onClick={handleRestaurantNameSubmit}
                    sx={{
                      ":hover": {
                        bgcolor: colors.themeBg.bgPrimary,
                        color: colors.themeText.textBlack,
                      },
                      bgcolor: colors.themeBg.bgBlack,
                      color: colors.themeBg.bgWhite,
                      fontSize: "16px",
                      padding: "12px 20px",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm="6"
              xs="12"
              p={{ xs: "20px", sm: "40px" }}
              mb={{ xs: 5, sm: 0 }}
              bgcolor={colors.themeBg.bgPrimary}
              sx={{ zIndex: "1", position: "relative" }}
            >
              <Typography
                sx={{ zIndex: "1", position: "relative" }}
                variant="h2"
                pb="24px"
                color={colors.themeText.textBlack}
              >
                Interested in becoming <br /> a Lister? Join the <br /> waitlist
                today
              </Typography>

              <Grid
                container
                spacing={"12px"}
                sx={{ zIndex: "1", position: "relative" }}
              >
                <Grid item sm={9} xs={12}>
                  <TextField
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Enter email address"
                    sx={{
                      backgroundColor: "#FFF",
                      borderRadius: "30px",
                      color: "#0D0D0D",
                      width: "100%",
                      fontSize: "14px",
                      "& input": {
                        py: { md: "14px", xs: "8px" },
                        paddingX: (theme) => theme.spacing(2.3),
                        fontSize: "14px",
                        fontWeight: "500",
                      },
                      "& fieldset": {
                        borderRadius: "30px",
                        border: "1px solid #0D0D0D",
                        color: "#0D0D0D",
                        fontSize: "14px",
                      },
                    }}
                  />
                  {emailError && (
                    <Typography color="error">{emailError}</Typography>
                  )}
                </Grid>

                <Grid item lg={3}>
                  <Button
                    onClick={handleEmailSubmit}
                    sx={{
                      ":hover": {
                        bgcolor: colors.themeBg.bgPrimary,
                        color: colors.themeText.textBlack,
                      },
                      bgcolor: colors.themeBg.bgBlack,
                      color: colors.themeBg.bgWhite,
                      fontSize: "16px",
                      padding: "12px 20px",
                      whiteSpace: " nowrap",
                      width: "100%",
                    }}
                  >
                    Join now
                  </Button>
                </Grid>
              </Grid>

              <Box className="ibimg">
                <img src={InterestedInBecoming} alt="" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        component="section"
        sx={{ bgcolor: colors.themeBg.bgBlack }}
        color={colors.themeText.TextWhite}
        p="20px 0"
      >
        <Container maxWidth="xl">
          <Grid container justifyContent={"space-between"}>
            <Box
              textAlign={"center"}
              display={"flex"}
              gap={"20px"}
              justifyContent={"center"}
            >
              <Link underline="none" href="/terms-and-conditions">
                Terms & Condition
              </Link>
              <Link underline="none" href="/privacy-policy">
                Privacy Policy
              </Link>
              <Link underline="none" href="/cookie-policy">
                Cookie Policy
              </Link>
            </Box>
            <Box textAlign={"center"}>
              <Link
                href="mailto:support@skipapp.com"
                underline="none"
                variant="h6"
                fontWeight="normal"
                color={colors.themeText.TextWhite}
                textAlign="center"
              >
                Contact us on : Support@skipapp.com
              </Link>
            </Box>
          </Grid>
        </Container>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1500}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Footer;
