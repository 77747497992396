import React from "react";
import { colors } from "../style/colors";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import HeroBanner from "../assets/images/HeroBanner.png";
import Footer from "../Components/Footer";
import {
  Backdrop,
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
const CoockiePilicy = () => {
  return (
    <>
      <Box
        component="section"
        sx={{
          bgcolor: colors.themeBg.bgBlack,
          position: "relative",
          backgroundImage: `url('${HeroBanner}')`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container sx={{ position: "relative", zIndex: 1 }} maxWidth="xl">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            py={{ sm: 3, xs: 1 }}
          >
            <Grid item xs="auto">
              <Link href="/">
                <Logo />
              </Link>
            </Grid>
            <Grid item xs="auto">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="buttonGhostTonal"
                  sx={{ px: 3, py: 1.5, mr: { md: 3, xs: 1 } }}
                >
                  Request Restaurant{" "}
                </Button>
                <Button variant="contained" sx={{ px: 3, py: 1.5 }}>
                  Become lister
                </Button>
              </Box>
            </Grid>
          </Grid>

          {/* Content */}
          <Grid
            container
            justifyContent="center"
            pt={{ sm: 7, xs: 4 }}
            pb={{ sm: 10, xs: 6 }}
          >
            <Grid item xs="12" md="6">
              <Typography
                variant="h1"
                mb={2}
                color={colors.themeText.TextWhite}
                textAlign="center"
              >
                COOKIE POLICY
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Backdrop
          open
          sx={{
            position: "absolute",
            inset: 0,
            bgcolor: colors.themeBg.bgBlack,
            opacity: "0.9 !important",
            filter: "blur(216)",
          }}
        ></Backdrop>
      </Box>

      <Box
        className="cstm-ctm"
        component="section"
        sx={{ py: { md: 10, xs: 6 } }}
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs="12" md="12" sx={{ pb: { md: 6, xs: 4 } }}>
              <Box>
                <>
                  <p>
                    <strong>
                      <span>Last updated October 16, 2024</span>
                    </strong>
                  </p>
                  <p>
                    <span>This Cookie Policy explains how Company("</span>
                    <strong>
                      <span>Company</span>
                    </strong>
                    <span>," "</span>
                    <strong>
                      <span>we</span>
                    </strong>
                    <span>," "</span>
                    <strong>
                      <span>us</span>
                    </strong>
                    <span>," and "</span>
                    <strong>
                      <span>our</span>
                    </strong>
                    <span>
                      ") uses cookies and similar technologies to recognize you
                      when you visit our website at&nbsp;
                    </span>
                    <a href="https://skipapp.com/">
                      <u>
                        <span>https://skipapp.com</span>
                      </u>
                    </a>
                    <span>&nbsp;("</span>
                    <strong>
                      <span>Website</span>
                    </strong>
                    <span>
                      "). It explains what these technologies are and why we use
                      them, as well as your rights to control our use of them.
                    </span>
                  </p>
                  <p>
                    <span>
                      In some cases we may use cookies to collect personal
                      information, or that becomes personal information if we
                      combine it with other information.
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span>What are cookies?</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Cookies are small data files that are placed on your
                      computer or mobile device when you visit a website.
                      Cookies are widely used by website owners in order to make
                      their websites work, or to work more efficiently, as well
                      as to provide reporting information.
                    </span>
                  </p>
                  <p>
                    <span>
                      Cookies set by the website owner are called "first-party
                      cookies." Cookies set by parties other than the website
                      owner are called "third-party cookies." Third-party
                      cookies enable third-party features or functionality to be
                      provided on or through the website (e.g., advertising,
                      interactive content, and analytics). The parties that set
                      these third-party cookies can recognize your computer both
                      when it visits the website in question and also when it
                      visits certain other websites.
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span>Why do we use cookies?</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      We use first- and third-party cookies for several reasons.
                      Some cookies are required for technical reasons in order
                      for our Website to operate, and we refer to these as
                      "essential" or "strictly necessary" cookies. Other cookies
                      also enable us to track and target the interests of our
                      users to enhance the experience on our Online Properties.
                      Third parties serve cookies through our Website for
                      advertising, analytics, and other purposes. This is
                      described in more detail below.
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span>How can I control cookies?</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      You have the right to decide whether to accept or reject
                      cookies. You can exercise your cookie rights by setting
                      your preferences in the Cookie Consent Manager. The Cookie
                      Consent Manager allows you to select which categories of
                      cookies you accept or reject. Essential cookies cannot be
                      rejected as they are strictly necessary to provide you
                      with services.
                    </span>
                  </p>
                  <p>
                    <span>
                      The Cookie Consent Manager can be found in the
                      notification banner and on our Website. If you choose to
                      reject cookies, you may still use our Website though your
                      access to some functionality and areas of our Website may
                      be restricted. You may also set or amend your web browser
                      controls to accept or refuse cookies.
                    </span>
                  </p>
                  <p>
                    <span>
                      The specific types of first- and third-party cookies
                      served through our Website and the purposes they perform
                      are described in the table below (please note that the
                      specific cookies served may vary depending on the specific
                      Online Properties you visit):
                    </span>
                  </p>
                  <p>
                    <strong>
                      <u>
                        <span>Essential website cookies:</span>
                      </u>
                    </strong>
                  </p>
                  <p>
                    <span>
                      These cookies are strictly necessary to provide you with
                      services available through our Website and to use some of
                      its features, such as access to secure areas.
                    </span>
                  </p>
                  <div>
                    <div>
                      <p>
                        <span>Name:</span> <span>__stripe_mid</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        <span>Purpose:</span>{" "}
                        <span>Fraud prevention and detection</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Provider:</span> <span>.skipapp.com</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Service:</span>{" "}
                        <span>Stripe View Service Privacy Policy</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Type:</span> <span>http_cookie</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Expires in:</span> <span>11 months 30 days</span>
                      </p>
                    </div>
                  </div>
                  <br />
                  <div>
                    <div>
                      <p>
                        <span>Name:</span> <span>__stripe_mid</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        <span>Purpose:</span>{" "}
                        <span>Fraud prevention and detection</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Provider:</span> <span>.skipapp.com</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Service:</span>{" "}
                        <span>Stripe View Service Privacy Policy</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Type:</span> <span>http_cookie</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Expires in:</span> <span>30 minutes</span>
                      </p>
                    </div>
                  </div>
                  <br />
                  <div>
                    <div>
                      <p>
                        <span>Name:</span> <span>__stripe_mid</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        <span>Purpose:</span>{" "}
                        <span>Fraud prevention and detection</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Provider:</span> <span>.skipapp.com</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Service:</span>{" "}
                        <span>Stripe View Service Privacy Policy</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Type:</span> <span>http_cookie</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Expires in:</span>{" "}
                        <span>1 year 11 months 29 days</span>
                      </p>
                    </div>
                  </div>
                  <p>
                    <strong>
                      <u>
                        <span>Unclassified cookies:</span>
                      </u>
                    </strong>
                  </p>
                  <p>
                    <span>
                      These are cookies that have not yet been categorized. We
                      are in the process of classifying these cookies with the
                      help of their providers.
                    </span>
                  </p>
                  <div>
                    <div>
                      <p>
                        <span>Name:</span> <span>mode</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        <span>Provider:</span> <span>.skipapp.com</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Type:</span> <span>html_local_storage</span>
                      </p>
                    </div>{" "}
                    <div>
                      <p>
                        <span>Expires in:</span> <span>persistent</span>
                      </p>
                    </div>
                  </div>
                  <p>
                    <strong>
                      <span>How can I control cookies on my browser?</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      As the means by which you can refuse cookies through your
                      web browser controls vary from browser to browser, you
                      should visit your browser's help menu for more
                      information. The following is information about how to
                      manage cookies on the most popular browsers:
                    </span>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <a href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies">
                          <u>
                            <span>Chrome</span>
                          </u>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                          <u>
                            <span>Internet Explorer</span>
                          </u>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
                          <u>
                            <span>Firefox</span>
                          </u>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac">
                          <u>
                            <span>Safari</span>
                          </u>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">
                          <u>
                            <span>Edge</span>
                          </u>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a href="https://help.opera.com/en/latest/web-preferences/">
                          <u>
                            <span>Opera</span>
                          </u>
                        </a>
                      </p>
                    </li>
                  </ul>
                  <p>
                    <span>
                      In addition, most advertising networks offer you a way to
                      opt out of targeted advertising. If you would like to find
                      out more information, please visit:
                    </span>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <a href="http://www.aboutads.info/choices/">
                          <u>
                            <span>Digital Advertising Alliance</span>
                          </u>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a href="https://youradchoices.ca/">
                          <u>
                            <span>Digital Advertising Alliance of Canada</span>
                          </u>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a href="http://www.youronlinechoices.com/">
                          <u>
                            <span>
                              European Interactive Digital Advertising Alliance
                            </span>
                          </u>
                        </a>
                      </p>
                    </li>
                  </ul>
                  <p>
                    <strong>
                      <span>
                        What about other tracking technologies, like web
                        beacons?
                      </span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Cookies are not the only way to recognize or track
                      visitors to a website. We may use other, similar
                      technologies from time to time, like web beacons
                      (sometimes called "tracking pixels" or "clear gifs").
                      These are tiny graphics files that contain a unique
                      identifier that enables us to recognize when someone has
                      visited our Website or opened an email including them.
                      This allows us, for example, to monitor the traffic
                      patterns of users from one page within a website to
                      another, to deliver or communicate with cookies, to
                      understand whether you have come to the website from an
                      online advertisement displayed on a third-party website,
                      to improve site performance, and to measure the success of
                      email marketing campaigns. In many instances, these
                      technologies are reliant on cookies to function properly,
                      and so declining cookies will impair their functioning.
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span>
                        Do you use Flash cookies or Local Shared Objects?
                      </span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Websites may also use so-called "Flash Cookies" (also
                      known as Local Shared Objects or "LSOs") to, among other
                      things, collect and store information about your use of
                      our services, fraud prevention, and for other site
                      operations.
                    </span>
                  </p>
                  <p>
                    <span>
                      If you do not want Flash Cookies stored on your computer,
                      you can adjust the settings of your Flash player to block
                      Flash Cookies storage using the tools contained in
                      the&nbsp;
                    </span>
                    <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">
                      <u>
                        <span>Website Storage Settings Panel</span>
                      </u>
                    </a>
                    <span>
                      . You can also control Flash Cookies by going to the&nbsp;
                    </span>
                    <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html">
                      <u>
                        <span>Global Storage Settings Panel</span>
                      </u>
                    </a>
                    <span>
                      &nbsp;and following the instructions (which may include
                      instructions that explain, for example, how to delete
                      existing Flash Cookies (referred to "information" on the
                      Macromedia site), how to prevent Flash LSOs from being
                      placed on your computer without your being asked, and (for
                      Flash Player 8 and later) how to block Flash Cookies that
                      are not being delivered by the operator of the page you
                      are on at the time).
                    </span>
                  </p>
                  <p>
                    <span>
                      Please note that setting the Flash Player to restrict or
                      limit acceptance of Flash Cookies may reduce or impede the
                      functionality of some Flash applications, including,
                      potentially, Flash applications used in connection with
                      our services or online content.
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span>Do you serve targeted advertising?</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Third parties may serve cookies on your computer or mobile
                      device to serve advertising through our Website. These
                      companies may use information about your visits to this
                      and other websites in order to provide relevant
                      advertisements about goods and services that you may be
                      interested in. They may also employ technology that is
                      used to measure the effectiveness of advertisements. They
                      can accomplish this by using cookies or web beacons to
                      collect information about your visits to this and other
                      sites in order to provide relevant advertisements about
                      goods and services of potential interest to you. The
                      information collected through this process does not enable
                      us or them to identify your name, contact details, or
                      other details that directly identify you unless you choose
                      to provide these.
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span>How often will you update this Cookie Policy?</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      We may update this Cookie Policy from time to time in
                      order to reflect, for example, changes to the cookies we
                      use or for other operational, legal, or regulatory
                      reasons. Please therefore revisit this Cookie Policy
                      regularly to stay informed about our use of cookies and
                      related technologies.
                    </span>
                  </p>
                  <p>
                    <span>
                      The date at the top of this Cookie Policy indicates when
                      it was last updated.
                    </span>
                  </p>
                  <p>
                    <strong>
                      <span>Where can I get further information?</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      If you have any questions about our use of cookies or
                      other technologies, please contact us at:
                    </span>
                  </p>
                </>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default CoockiePilicy;
