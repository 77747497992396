import React from "react";
import { colors } from "../style/colors";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import HeroBanner from "../assets/images/HeroBanner.png";
import Footer from "../Components/Footer";
import {
  Backdrop,
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
const PrivacyPolicy = () => {
  return (
    <>
      <Box
        component="section"
        sx={{
          bgcolor: colors.themeBg.bgBlack,
          position: "relative",
          backgroundImage: `url('${HeroBanner}')`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container sx={{ position: "relative", zIndex: 1 }} maxWidth="xl">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            py={{ sm: 3, xs: 1 }}
          >
            <Grid item xs="auto">
              <Link href="/">
                <Logo />
              </Link>
            </Grid>
            <Grid item xs="auto">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="buttonGhostTonal"
                  sx={{ px: 3, py: 1.5, mr: { md: 3, xs: 1 } }}
                >
                  Request Restaurant{" "}
                </Button>
                <Button variant="contained" sx={{ px: 3, py: 1.5 }}>
                  Become lister
                </Button>
              </Box>
            </Grid>
          </Grid>

          {/* Content */}
          <Grid
            container
            justifyContent="center"
            pt={{ sm: 7, xs: 4 }}
            pb={{ sm: 10, xs: 6 }}
          >
            <Grid item xs="12" md="6">
              <Typography
                variant="h1"
                mb={2}
                color={colors.themeText.TextWhite}
                textAlign="center"
              >
                PRIVACY POLICY
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Backdrop
          open
          sx={{
            position: "absolute",
            inset: 0,
            bgcolor: colors.themeBg.bgBlack,
            opacity: "0.9 !important",
            filter: "blur(216)",
          }}
        ></Backdrop>
      </Box>
      <Box
        className="cstm-ctm"
        component="section"
        sx={{ py: { md: 10, xs: 6 } }}
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs="12" md="12" sx={{ pb: { md: 6, xs: 4 } }}>
              <Box>
                <p>
                  <strong>
                    <span>Last updated October 15, 2024</span>
                  </strong>
                  <br />
                  <br />
                </p>
                <p>
                  <span>This Privacy Notice for Skip, LLC ("</span>
                  <strong>
                    <span>we</span>
                  </strong>
                  <span>," "</span>
                  <strong>
                    <span>us</span>
                  </strong>
                  <span>," or "</span>
                  <strong>
                    <span>our</span>
                  </strong>
                  <span>
                    "), describes how and why we might access, collect, store,
                    use, and/or share ("
                  </span>
                  <strong>
                    <span>process</span>
                  </strong>
                  <span>
                    ") your personal information when you use our services ("
                  </span>
                  <strong>
                    <span>Services</span>
                  </strong>
                  <span>"), including when you:</span>
                </p>
                <ul>
                  <li>
                    <p>
                      <span>
                        Visit our website at skipapp.com, or any website of ours
                        that links to this Privacy Notice
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Download and use our mobile application (Skip App), or
                        any other application of ours that links to this Privacy
                        Notice
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Use&nbsp;</span>
                      <span>Skip</span>
                      <span>.&nbsp;</span>
                      <span>
                        A platform that allows users to interact with the
                        objective of skipping venue lines that do not take
                        reservations.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Engage with us in other related ways, including any
                        sales, marketing, or events
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <strong>
                    <span>Questions or concerns?&nbsp;</span>
                  </strong>
                  <span>
                    Reading this Privacy Notice will help you understand your
                    privacy rights and choices. We are responsible for making
                    decisions about how your personal information is processed.
                    If you do not agree with our policies and practices, please
                    do not use our Services. If you still have any questions or
                    concerns, please contact us at{" "}
                    <a href="mailto:support@skipapp.com">support@skipapp.com</a>
                    .
                  </span>
                </p>

                <p>
                  <strong>
                    <span>SUMMARY OF KEY POINTS</span>
                  </strong>
                </p>
                <p>
                  <strong>
                    <em>
                      <span>
                        This summary provides key points from our Privacy
                        Notice, but you can find out more details about any of
                        these topics by clicking the link following each key
                        point or by using our table of contents
                      </span>
                    </em>
                  </strong>
                  <strong>
                    <em>
                      <span>
                        &nbsp;below to find the section you are looking for.
                      </span>
                    </em>
                  </strong>
                </p>
                <p>
                  <strong>
                    <span>What personal information do we process?</span>
                  </strong>
                  <span>
                    &nbsp;When you visit, use, or navigate our Services, we may
                    process personal information depending on how you interact
                    with us and the Services, the choices you make, and the
                    products and features you use.
                  </span>
                </p>
                <p>
                  <strong>
                    <span>
                      Do we process any sensitive personal information?&nbsp;
                    </span>
                  </strong>
                  <span>
                    Some of the information may be considered "special" or
                    "sensitive" in certain jurisdictions, for example your
                    racial or ethnic origins, sexual orientation, and religious
                    beliefs. We do not process sensitive personal information.
                  </span>
                </p>
                <p>
                  <strong>
                    <span>
                      Do we collect any information from third parties?
                    </span>
                  </strong>
                  <span>
                    &nbsp;We do not collect any information from third parties.
                  </span>
                </p>
                <p>
                  <strong>
                    <span>How do we process your information?</span>
                  </strong>
                  <span>
                    &nbsp;We process your information to provide, improve, and
                    administer our Services, communicate with you, for security
                    and fraud prevention, and to comply with law. We may also
                    process your information for other purposes with your
                    consent. We process your information only when we have a
                    valid legal reason to do so.
                  </span>
                </p>
                <p>
                  <strong>
                    <span>What are your rights?</span>
                  </strong>
                  <span>
                    &nbsp;Depending on where you are located geographically, the
                    applicable privacy law may mean you have certain rights
                    regarding your personal information. Learn more about&nbsp;
                  </span>
                  <a href="/privacy-policy">
                    <u>
                      <span>your privacy rights</span>
                    </u>
                  </a>
                  <span>.</span>
                </p>
                <p>
                  <strong>
                    <span>How do you exercise your rights?</span>
                  </strong>
                  <span>
                    &nbsp;The easiest way to exercise your rights is by
                    submitting a data subject access request
                  </span>
                  <span>
                    , or by contacting us. We will consider and act upon any
                    request in accordance with applicable data protection laws.
                  </span>
                </p>
                <p>
                  <span>
                    Want to learn more about what we do with any information we
                    collect? Review the Privacy Notice in full.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>TABLE OF CONTENTS</span>
                  </strong>
                </p>

                <p>
                  <a href="#list-1">
                    <u>
                      <span>1. WHAT INFORMATION DO WE COLLECT?</span>
                    </u>
                  </a>
                </p>
                <p>
                  <a href="#list-2">
                    <u>
                      <span>2. HOW DO WE PROCESS YOUR INFORMATION?</span>
                    </u>
                  </a>
                </p>
                <p>
                  <a href="#list-4">
                    <u>
                      <span>
                        3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                      </span>
                    </u>
                  </a>
                </p>
                <p>
                  <a href="#list-6">
                    <u>
                      <span>4. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
                    </u>
                  </a>
                </p>
                <p>
                  <a href="#list-7">
                    <u>
                      <span>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
                    </u>
                  </a>
                </p>
                <p>
                  <a href="#list-8">
                    <u>
                      <span>6. DO WE COLLECT INFORMATION FROM MINORS?</span>
                    </u>
                  </a>
                </p>
                <p>
                  <a href="#list-9">
                    <u>
                      <span>7. WHAT ARE YOUR PRIVACY RIGHTS?</span>
                    </u>
                  </a>
                </p>
                <p>
                  <a href="#list-10">
                    <u>
                      <span>8. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
                    </u>
                  </a>
                </p>
                <p>
                  <a href="#list-11">
                    <u>
                      <span>
                        9. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY
                        RIGHTS?
                      </span>
                    </u>
                  </a>
                </p>
                <p>
                  <a href="#list-12">
                    <u>
                      <span>10. DO WE MAKE UPDATES TO THIS NOTICE?</span>
                    </u>
                  </a>
                </p>
                <p>
                  <a href="#list-13">
                    <u>
                      <span>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                    </u>
                  </a>
                </p>
                <p>
                  <a href="#list-14">
                    <u>
                      <span>
                        12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                        COLLECT FROM YOU?
                      </span>
                    </u>
                  </a>
                </p>

                <p id="list-1">
                  <strong>
                    <span>1. WHAT INFORMATION DO WE COLLECT?</span>
                  </strong>
                </p>
                <p>
                  <strong>
                    <span>Personal information you disclose to us</span>
                  </strong>
                </p>
                <p>
                  <strong>
                    <em>
                      <span>In Short:&nbsp;</span>
                    </em>
                  </strong>
                  <em>
                    <span>
                      We collect personal information that you provide to us.
                    </span>
                  </em>
                </p>
                <p>
                  <span>
                    We collect personal information that you voluntarily provide
                    to us when you register on the Services, express an interest
                    in obtaining information about us or our products and
                    Services, when you participate in activities on the
                    Services, or otherwise when you contact us.
                  </span>
                </p>
                <p>
                  <strong>
                    <span>Personal Information Provided by You.</span>
                  </strong>
                  <span>
                    &nbsp;The personal information that we collect depends on
                    the context of your interactions with us and the Services,
                    the choices you make, and the products and features you use.
                    The personal information we collect may include the
                    following:
                  </span>
                </p>
                <ul>
                  <li>
                    <p>
                      <span>names</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>phone numbers</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>email addresses</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>mailing addresses</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>usernames</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>passwords</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>contact preferences</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>contact or authentication data</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>billing addresses</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>debit/credit card numbers</span>
                    </p>
                  </li>
                </ul>
                <p>
                  <strong>
                    <span>Sensitive Information.</span>
                  </strong>
                  <span>&nbsp;We do not process sensitive information.</span>
                </p>
                <p>
                  <strong>
                    <span>Payment Data.</span>
                  </strong>
                  <span>
                    &nbsp;We may collect data necessary to process your payment
                    if you choose to make purchases, such as your payment
                    instrument number, and the security code associated with
                    your payment instrument. All payment data is handled and
                    stored by Stripe. You may find their privacy notice link(s)
                    here: stripe.com.
                  </span>
                </p>
                <p>
                  <strong>
                    <span>Social Media Login Data.&nbsp;</span>
                  </strong>
                  <span>
                    We may provide you with the option to register with us using
                    your existing social media account details, like your
                    Facebook, X, or other social media account. If you choose to
                    register in this way, we will collect certain profile
                    information about you from the social media provider, as
                    described in the section called "
                  </span>
                  <a>
                    <u>
                      <span>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
                    </u>
                  </a>
                  <span>" below.</span>
                </p>
                <p>
                  <strong>
                    <span>Application Data.</span>
                  </strong>
                  <span>
                    &nbsp;If you use our application(s), we also may collect the
                    following information if you choose to provide us with
                    access or permission:
                  </span>
                </p>
                <ul>
                  <li>
                    <p>
                      <em>
                        <span>Geolocation Information.</span>
                      </em>
                      <span>
                        &nbsp;We may request access or permission to track
                        location-based information from your mobile device,
                        either continuously or while you are using our mobile
                        application(s), to provide certain location-based
                        services. If you wish to change our access or
                        permissions, you may do so in your device's settings.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <em>
                        <span>Mobile Device Access.</span>
                      </em>
                      <span>
                        &nbsp;We may request access or permission to certain
                        features from your mobile device, including your mobile
                        device's contacts, reminders, sms messages, social media
                        accounts, and other features. If you wish to change our
                        access or permissions, you may do so in your device's
                        settings.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <em>
                        <span>Mobile Device Data.</span>
                      </em>
                      <span>
                        &nbsp;We automatically collect device information (such
                        as your mobile device ID, model, and manufacturer),
                        operating system, version information and system
                        configuration information, device and application
                        identification numbers, browser type and version,
                        hardware model Internet service provider and/or mobile
                        carrier, and Internet Protocol (IP) address (or proxy
                        server). If you are using our application(s), we may
                        also collect information about the phone network
                        associated with your mobile device, your mobile device’s
                        operating system or platform, the type of mobile device
                        you use, your mobile device’s unique device ID, and
                        information about the features of our application(s) you
                        accessed.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <em>
                        <span>Push Notifications.</span>
                      </em>
                      <span>
                        &nbsp;We may request to send you push notifications
                        regarding your account or certain features of the
                        application(s). If you wish to opt out from receiving
                        these types of communications, you may turn them off in
                        your device's settings.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <span>
                    This information is primarily needed to maintain the
                    security and operation of our application(s), for
                    troubleshooting, and for our internal analytics and
                    reporting purposes.
                  </span>
                </p>
                <p>
                  <span>
                    All personal information that you provide to us must be
                    true, complete, and accurate, and you must notify us of any
                    changes to such personal information.
                  </span>
                </p>
                <p>
                  <strong>
                    <span>Information automatically collected</span>
                  </strong>
                </p>
                <p>
                  <strong>
                    <em>
                      <span>In Short:&nbsp;</span>
                    </em>
                  </strong>
                  <em>
                    <span>
                      Some information — such as your Internet Protocol (IP)
                      address and/or browser and device characteristics — is
                      collected automatically when you visit our Services.
                    </span>
                  </em>
                </p>
                <p>
                  <span>
                    We automatically collect certain information when you visit,
                    use, or navigate the Services. This information does not
                    reveal your specific identity (like your name or contact
                    information) but may include device and usage information,
                    such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs,
                    device name, country, location, information about how and
                    when you use our Services, and other technical information.
                    This information is primarily needed to maintain the
                    security and operation of our Services, and for our internal
                    analytics and reporting purposes.
                  </span>
                </p>
                <p>
                  <span>
                    Like many businesses, we also collect information through
                    cookies and similar technologies.
                  </span>
                </p>
                <p>
                  <span>The information we collect includes:</span>
                </p>
                <ul>
                  <li>
                    <p>
                      <em>
                        <span>Log and Usage Data.</span>
                      </em>
                      <span>
                        &nbsp;Log and usage data is service-related, diagnostic,
                        usage, and performance information our servers
                        automatically collect when you access or use our
                        Services and which we record in log files. Depending on
                        how you interact with us, this log data may include your
                        IP address, device information, browser type, and
                        settings and information about your activity in the
                        Services
                      </span>
                      <span>&nbsp;</span>
                      <span>
                        (such as the date/time stamps associated with your
                        usage, pages and files viewed, searches, and other
                        actions you take such as which features you use), device
                        event information (such as system activity, error
                        reports (sometimes called "crash dumps"), and hardware
                        settings).
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <em>
                        <span>Device Data.</span>
                      </em>
                      <span>
                        &nbsp;We collect device data such as information about
                        your computer, phone, tablet, or other device you use to
                        access the Services. Depending on the device used, this
                        device data may include information such as your IP
                        address (or proxy server), device and application
                        identification numbers, location, browser type, hardware
                        model, Internet service provider and/or mobile carrier,
                        operating system, and system configuration information.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <em>
                        <span>Location Data.</span>
                      </em>
                      <span>
                        &nbsp;We collect location data such as information about
                        your device's location, which can be either precise or
                        imprecise. How much information we collect depends on
                        the type and settings of the device you use to access
                        the Services. For example, we may use GPS and other
                        technologies to collect geolocation data that tells us
                        your current location (based on your IP address). You
                        can opt out of allowing us to collect this information
                        either by refusing access to the information or by
                        disabling your Location setting on your device. However,
                        if you choose to opt out, you may not be able to use
                        certain aspects of the Services.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <strong>
                    <span>Google API</span>
                  </strong>
                </p>
                <p>
                  <span>
                    Our use of information received from Google APIs will adhere
                    to&nbsp;
                  </span>
                  <a href="https://developers.google.com/terms/api-services-user-data-policy">
                    <u>
                      <span>Google API Services User Data Policy</span>
                    </u>
                  </a>
                  <span>, including the Limited Use requirements. </span>
                </p>

                <p id="list-2">
                  <strong>
                    <span>2. HOW DO WE PROCESS YOUR INFORMATION?</span>
                  </strong>
                </p>

                <p>
                  <strong>
                    <em>
                      <span>In Short:&nbsp;</span>
                    </em>
                  </strong>
                  <em>
                    <span>
                      We process your information to provide, improve, and
                      administer our Services, communicate with you, for
                      security and fraud prevention, and to comply with law. We
                      may also process your information for other purposes with
                      your consent.
                    </span>
                  </em>
                </p>
                <p>
                  <strong>
                    <span>
                      We process your personal information for a variety of
                      reasons, depending on how you interact with our Services,
                      including:
                    </span>
                  </strong>
                </p>
                <ul>
                  <li>
                    <p>
                      <strong>
                        <span>
                          To facilitate account creation and authentication and
                          otherwise manage user accounts.&nbsp;
                        </span>
                      </strong>
                      <span>
                        We may process your information so you can create and
                        log in to your account, as well as keep your account in
                        working order.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>
                          To deliver and facilitate delivery of services to the
                          user.&nbsp;
                        </span>
                      </strong>
                      <span>
                        We may process your information to provide you with the
                        requested service.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>
                          To respond to user inquiries/offer support to
                          users.&nbsp;
                        </span>
                      </strong>
                      <span>
                        We may process your information to respond to your
                        inquiries and solve any potential issues you might have
                        with the requested service.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>
                          To send administrative information to you.&nbsp;
                        </span>
                      </strong>
                      <span>
                        We may process your information to send you details
                        about our products and services, changes to our terms
                        and policies, and other similar information.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>To fulfill and manage your orders.&nbsp;</span>
                      </strong>
                      <span>
                        We may process your information to fulfill and manage
                        your orders, payments, returns, and exchanges made
                        through the Services.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>
                          To enable user-to-user communications.&nbsp;
                        </span>
                      </strong>
                      <span>
                        We may process your information if you choose to use any
                        of our offerings that allow for communication with
                        another user.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>To request feedback.&nbsp;</span>
                      </strong>
                      <span>
                        We may process your information when necessary to
                        request feedback and to contact you about your use of
                        our Services.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>
                          To send you marketing and promotional
                          communications.&nbsp;
                        </span>
                      </strong>
                      <span>
                        We may process the personal information you send to us
                        for our marketing purposes, if this is in accordance
                        with your marketing preferences. You can opt out of our
                        marketing emails at any time. For more information, see
                        "WHAT ARE YOUR PRIVACY RIGHTS?" below.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>
                          To deliver targeted advertising to you.&nbsp;
                        </span>
                      </strong>
                      <span>
                        We may process your information to develop and display
                        personalized content and advertising tailored to your
                        interests, location, and more.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>To protect our Services.</span>
                      </strong>
                      <span>
                        &nbsp;We may process your information as part of our
                        efforts to keep our Services safe and secure, including
                        fraud monitoring and prevention.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>
                          To evaluate and improve our Services, products,
                          marketing, and your experience.
                        </span>
                      </strong>
                      <span>
                        &nbsp;We may process your information when we believe it
                        is necessary to identify usage trends, determine the
                        effectiveness of our promotional campaigns, and to
                        evaluate and improve our Services, products, marketing,
                        and your experience.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>To identify usage trends.</span>
                      </strong>
                      <span>
                        &nbsp;We may process information about how you use our
                        Services to better understand how they are being used so
                        we can improve them.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>
                          To determine the effectiveness of our marketing and
                          promotional campaigns.
                        </span>
                      </strong>
                      <span>
                        &nbsp;We may process your information to better
                        understand how to provide marketing and promotional
                        campaigns that are most relevant to you.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>To comply with our legal obligations.</span>
                      </strong>
                      <span>
                        &nbsp;We may process your information to comply with our
                        legal obligations, respond to legal requests, and
                        exercise, establish, or defend our legal rights.
                      </span>
                    </p>
                  </li>
                </ul>


                <p id="list-4">
                  <strong>
                    <span>
                      3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </span>
                  </strong>
                </p>
                <p>
                  <strong>
                    <em>
                      <span>In Short:</span>
                    </em>
                  </strong>
                  <em>
                    <span>
                      &nbsp;We may use cookies and other tracking technologies
                      to collect and store your information.
                    </span>
                  </em>
                </p>
                <p>
                  <span>
                    We may use cookies and similar tracking technologies (like
                    web beacons and pixels) to gather information when you
                    interact with our Services. Some online tracking
                    technologies help us maintain the security of our Services
                    and your account, prevent crashes, fix bugs, save your
                    preferences, and assist with basic site functions.
                  </span>
                </p>
                <p>
                  <span>
                    To the extent these online tracking technologies are deemed
                    to be a "sale" (which includes targeted
                    advertising, as defined under the applicable laws) under
                    applicable US state laws, you can opt out of these online
                    tracking technologies by submitting a request as described
                    below under section "DO UNITED STATES RESIDENTS HAVE
                    SPECIFIC PRIVACY RIGHTS?""
                  </span>
                </p>
                <p>
                  <span>
                    Specific information about how we use such technologies and
                    how you can refuse certain cookies is set out in our Cookie
                    Notice.
                  </span>
                </p>
                <p id="list-6">
                  <strong>
                    <span>4. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
                  </strong>
                </p>

                <p>
                  <strong>
                    <em>
                      <span>In Short:&nbsp;</span>
                    </em>
                  </strong>
                  <em>
                    <span>
                      We keep your information for as long as necessary to
                      fulfill the purposes outlined in this Privacy Notice
                      unless otherwise required by law.
                    </span>
                  </em>
                </p>
                <p>
                  <span>
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this Privacy
                    Notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting, or other legal
                    requirements). No purpose in this notice will require us
                    keeping your personal information for longer than the period
                    of time in which users have an account with us.
                  </span>
                </p>
                <p>
                  <span>
                    When we have no ongoing legitimate business need to process
                    your personal information, we will either delete or
                    anonymize such information, or, if this is not possible (for
                    example, because your personal information has been stored
                    in backup archives), then we will securely store your
                    personal information and isolate it from any further
                    processing until deletion is possible.
                  </span>
                </p>

                <p id="list-7">
                  <strong>
                    <span>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
                  </strong>
                </p>

                <p>
                  <strong>
                    <em>
                      <span>In Short:&nbsp;</span>
                    </em>
                  </strong>
                  <em>
                    <span>
                      We aim to protect your personal information through a
                      system of organizational and technical security measures.
                    </span>
                  </em>
                </p>

                <p id="list-8">
                  <strong>
                    <span>6. DO WE COLLECT INFORMATION FROM MINORS?</span>
                  </strong>
                </p>

                <p>
                  <strong>
                    <em>
                      <span>I</span>
                    </em>
                  </strong>
                  <strong>
                    <em>
                      <span>n Short:</span>
                    </em>
                  </strong>
                  <em>
                    <span>
                      &nbsp;We do not knowingly collect data from or market to
                      children under 18 years of age.
                    </span>
                  </em>
                </p>
                <p>
                  <span>
                    We do not knowingly collect, solicit data from, or market to
                    children under 18 years of age, nor do we knowingly sell
                    such personal information. By using the Services, you
                    represent that you are at least 18 or that you are the
                    parent or guardian of such a minor and consent to such minor
                    dependent’s use of the Services. If we learn that personal
                    information from users less than 18 years of age has been
                    collected, we will deactivate the account and take
                    reasonable measures to promptly delete such data from our
                    records. If you become aware of any data we may have
                    collected from children under age 18, please contact us at
                    __________.
                  </span>
                </p>

                <p id="list-9">
                  <strong>
                    <span>7. WHAT ARE YOUR PRIVACY RIGHTS?</span>
                  </strong>
                </p>

                <p>
                  <strong>
                    <em>
                      <span>In Short:</span>
                    </em>
                  </strong>
                  <em>
                    <span>
                      &nbsp;You may review, change, or terminate your account at
                      any time, depending on your country, province, or state of
                      residence.
                    </span>
                  </em>
                </p>
                <p>
                  <strong>
                    <u>
                      <span>Withdrawing your consent:</span>
                    </u>
                  </strong>
                  <span>
                    &nbsp;If we are relying on your consent to process your
                    personal information, which may be express and/or implied
                    consent depending on the applicable law, you have the right
                    to withdraw your consent at any time. You can withdraw your
                    consent at any time by contacting us by using the contact
                    details provided in the section
                  </span>
                  <a href="mailto:support@skipapp.com">
                    <u>
                      <span>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                    </u>
                  </a>
                  <span>" below.</span>
                </p>
                <p>
                  <span>
                    However, please note that this will not affect the
                    lawfulness of the processing before its withdrawal nor, when
                    applicable law allows, will it affect the processing of your
                    personal information conducted in reliance on lawful
                    processing grounds other than consent.
                  </span>
                </p>
                <p>
                  <strong>
                    <u>
                      <span>
                        Opting out of marketing and promotional communications:
                      </span>
                    </u>
                  </strong>
                  <strong>
                    <span>&nbsp;</span>
                  </strong>
                  <span>
                    You can unsubscribe from our marketing and promotional
                    communications at any time by clicking on the unsubscribe
                    link in the emails that we send, replying "STOP" or
                    "UNSUBSCRIBE" to the SMS messages that we send, or by
                    contacting us using the details provided in the section "
                  </span>
                  <a href="mailto:support@skipapp.com">
                    <u>
                      <span>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                    </u>
                  </a>
                  <span>
                    " below. You will then be removed from the marketing lists.
                    However, we may still communicate with you — for example, to
                    send you service-related messages that are necessary for the
                    administration and use of your account, to respond to
                    service requests, or for other non-marketing purposes.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Account Information</span>
                  </strong>
                </p>

                <p>
                  <span>
                    If you would at any time like to review or change the
                    information in your account or terminate your account, you
                    can:
                  </span>
                </p>
                <ul>
                  <li>
                    <p>
                      <span>
                        Log in to your account settings and update your user
                        account.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <span>
                    Upon your request to terminate your account, we will
                    deactivate or delete your account and information from our
                    active databases. However, we may retain some information in
                    our files to prevent fraud, troubleshoot problems, assist
                    with any investigations, enforce our legal terms and/or
                    comply with applicable legal requirements.
                  </span>
                </p>
                <p>
                  <strong>
                    <u>
                      <span>Cookies and similar technologies:</span>
                    </u>
                  </strong>
                  <span>
                    &nbsp;Most Web browsers are set to accept cookies by
                    default. If you prefer, you can usually choose to set your
                    browser to remove cookies and to reject cookies. If you
                    choose to remove cookies or reject cookies, this could
                    affect certain features or services of our Services.
                  </span>
                </p>
                <p>
                  <span>
                    If you have questions or comments about your privacy rights,
                    you may email us at&nbsp;
                  </span>
                  <span>support@skipapp.com</span>
                  <span>.</span>
                </p>

                <p id="list-10">
                  <strong>
                    <span>8. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Most web browsers and some mobile operating systems and
                    mobile applications include a Do-Not-Track ("DNT") feature
                    or setting you can activate to signal your privacy
                    preference not to have data about your online browsing
                    activities monitored and collected. At this stage, no
                    uniform technology standard for recognizing and implementing
                    DNT signals has been finalized. As such, we do not currently
                    respond to DNT browser signals or any other mechanism that
                    automatically communicates your choice not to be tracked
                    online. If a standard for online tracking is adopted that we
                    must follow in the future, we will inform you about that
                    practice in a revised version of this Privacy Notice.
                  </span>
                </p>
                <p>
                  <span>
                    California law requires us to let you know how we respond to
                    web browser DNT signals. Because there currently is not an
                    industry or legal standard for recognizing or honoring DNT
                    signals, we do not respond to them at this time.
                  </span>
                </p>

                <p id="list-11">
                  <strong>
                    <span>
                      9. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY
                      RIGHTS?
                    </span>
                  </strong>
                </p>

                <p>
                  <strong>
                    <em>
                      <span>In Short:&nbsp;</span>
                    </em>
                  </strong>
                  <em>
                    <span>
                      If you are a resident of California, Colorado,
                      Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky,
                      Montana, New Hampshire, New Jersey, Oregon, Tennessee,
                      Texas, Utah, or Virginia, you may have the right to
                      request access to and receive details about the personal
                      information we maintain about you and how we have
                      processed it, correct inaccuracies, get a copy of, or
                      delete your personal information. You may also have the
                      right to withdraw your consent to our processing of your
                      personal information. These rights may be limited in some
                      circumstances by applicable law. More information is
                      provided below.
                    </span>
                  </em>
                </p>

                <>
                  <p>
                    <strong>
                      <span>Categories of Personal Information We Collect</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      We have collected the following categories of personal
                      information in the past twelve (12) months:
                    </span>
                  </p>

                  <div align="left">
                    <table className="tbl-pp">
                      <tbody>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              <b> Category</b>
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              <b> Examples</b>
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              <b> Collected</b>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>A. Identifiers</span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              Contact details, such as real name, alias, postal
                              address, telephone or mobile contact number,
                              unique personal identifier, online identifier,
                              Internet Protocol address, email address, and
                              account name
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>NO</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              B. Personal information as defined in the
                              California Customer Records statute
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              Name, contact information, education, employment,
                              employment history, and financial information
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>NO</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              C. Protected classification characteristics under
                              state or federal law
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              Gender, age, date of birth, race and ethnicity,
                              national origin, marital status, and other
                              demographic data
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>NO</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>D. Commercial information</span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              Transaction information, purchase history,
                              financial details, and payment information
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>NO</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>E. Biometric information</span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>Fingerprints and voiceprints</span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>NO</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              F. Internet or other similar network activity
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              Browsing history, search history, online behavior,
                              interest data, and interactions with our and other
                              websites, applications, systems, and
                              advertisements
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>NO</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>G. Geolocation data</span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>Device location</span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>NO</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              H. Audio, electronic, sensory, or similar
                              information
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              Images and audio, video or call recordings created
                              in connection with our business activities
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>NO</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              I. Professional or employment-related information
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              Business contact details in order to provide you
                              our Services at a business level or job title,
                              work history, and professional qualifications if
                              you apply for a job with us
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>NO</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>J. Education Information</span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              Student records and directory information
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>NO</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              K. Inferences drawn from collected personal
                              information
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>
                              Inferences drawn from any of the collected
                              personal information listed above to create a
                              profile or summary about, for example, an
                              individual’s preferences and characteristics
                            </span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>NO</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>L. Sensitive personal Information</span>
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <br />
                          </td>
                          <td style={{ border: "solid #000000 0.75pt" }}>
                            <span>NO</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p>
                    <br />
                  </p>
                  <p>
                    <span>
                      We may also collect other personal information outside of
                      these categories through instances where you interact with
                      us in person, online, or by phone or mail in the context
                      of:
                    </span>
                  </p>
                  <ul>
                    <li
                      style={{
                        listStyleType: "square",
                        color: "#595959",
                        fontSize: "10.5pt",
                        fontFamily: "Arial,sans-serif",
                      }}
                    >
                      <p>
                        <span
                          style={{
                            color: "#595959",
                            fontSize: "11.5pt",
                            fontFamily: "Arial,sans-serif",
                          }}
                        >
                          Receiving help through our customer support channels;
                        </span>
                      </p>
                    </li>
                    <li
                      style={{
                        listStyleType: "square",
                        color: "#595959",
                        fontSize: "10.5pt",
                        fontFamily: "Arial,sans-serif",
                      }}
                    >
                      <p>
                        <span
                          style={{
                            color: "#595959",
                            fontSize: "11.5pt",
                            fontFamily: "Arial,sans-serif",
                          }}
                        >
                          Participation in customer surveys or contests; and
                        </span>
                      </p>
                    </li>
                    <li
                      style={{
                        listStyleType: "square",
                        color: "#595959",
                        fontSize: "10.5pt",
                        fontFamily: "Arial,sans-serif",
                      }}
                    >
                      <p>
                        <span
                          style={{
                            color: "#595959",
                            fontSize: "11.5pt",
                            fontFamily: "Arial,sans-serif",
                          }}
                        >
                          Facilitation in the delivery of our Services and to
                          respond to your inquiries.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <p>
                    <span>
                      We will use and retain the collected personal information
                      as needed to provide the Services or for:
                    </span>
                  </p>
                  <ul>
                    <li
                      style={{
                        listStyleType: "square",
                        color: "#595959",
                        fontSize: "10.5pt",
                        fontFamily: "Arial,sans-serif",
                      }}
                    >
                      <p>
                        <span>
                          Category H - As long as the user has an account with
                          us
                        </span>
                      </p>
                    </li>
                  </ul>
                </>

                <p>
                  <strong>
                    <span>Sources of Personal Information</span>
                  </strong>
                </p>
                <p>
                  <span>
                    Learn more about the sources of personal information we
                    collect in "WHAT INFORMATION DO WE COLLECT?"
                  </span>
                </p>
                <p>
                  <strong>
                    <span>How We Use and Share Personal Information</span>
                  </strong>
                </p>
                <p>
                  <span>
                    Learn about how we use your personal information in the
                    section, "HOW DO WE PROCESS YOUR INFORMATION?"
                  </span>
                </p>
                <p>
                  <strong>
                    <span>
                      Will your information be shared with anyone else?
                    </span>
                  </strong>
                </p>
                <p>
                  <span>
                    We may disclose your personal information with our service
                    providers pursuant to a written contract between us and each
                    service provider. Learn more about how we disclose personal
                    information to in the section, "WHEN AND WITH WHOM DO WE
                    SHARE YOUR PERSONAL INFORMATION?"
                  </span>
                </p>
                <p>
                  <span>
                    We may use your personal information for our own business
                    purposes, such as for undertaking internal research for
                    technological development and demonstration. This is not
                    considered to be "selling" of your personal information.
                  </span>
                </p>
                <p>
                  <strong>
                    <span>Your Rights</span>
                  </strong>
                </p>
                <p>
                  <span>
                    You have rights under certain US state data protection laws.
                    However, these rights are not absolute, and in certain
                    cases, we may decline your request as permitted by law.
                    These rights include:
                  </span>
                </p>
                <ul>
                  <li>
                    <p>
                      <strong>
                        <span>Right to know</span>
                      </strong>
                      <span>
                        &nbsp;whether or not we are processing your personal
                        data
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>Right to access&nbsp;</span>
                      </strong>
                      <span>your personal data</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>Right to correct&nbsp;</span>
                      </strong>
                      <span>inaccuracies in your personal data</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>Right to request</span>
                      </strong>
                      <span>&nbsp;the deletion of your personal data</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>Right to obtain a copy&nbsp;</span>
                      </strong>
                      <span>
                        of the personal data you previously shared with us
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>Right to non-discrimination</span>
                      </strong>
                      <span>&nbsp;for exercising your rights</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        <span>Right to opt out</span>
                      </strong>
                      <span>
                        &nbsp;of the processing of your personal data if it is
                        used for targeted advertising (defined
                        under California’s privacy law), the sale of personal
                        data, or profiling in furtherance of decisions that
                        produce legal or similarly significant effects
                        ("profiling")
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <span>
                    Depending upon the state where you live, you may also have
                    the following rights:
                  </span>
                </p>
                <ul>
                  <li>
                    <p>
                      <span>
                        Right to limit use and disclosure of sensitive personal
                        data (as permitted by applicable law, including
                        California’s privacy law)
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Right to opt out of the collection of sensitive data and
                        personal data collected through the operation of a voice
                        or facial recognition feature (as permitted by
                        applicable law, including Florida’s privacy law)
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <strong>
                    <span>How to Exercise Your Rights</span>
                  </strong>
                </p>
                <p>
                  <span>
                    To exercise these rights, you can contact us by submitting a
                    data subject access request
                  </span>
                  <span>
                    , or by referring to the contact details at the bottom of
                    this document.
                  </span>
                </p>
                <p>
                  <span>
                    Under certain US state data protection laws, you can
                    designate an authorized agent to make a request on your
                    behalf. We may deny a request from an authorized agent that
                    does not submit proof that they have been validly authorized
                    to act on your behalf in accordance with applicable laws.
                  </span>
                </p>
                <p>
                  <strong>
                    <span>Request Verification</span>
                  </strong>
                </p>
                <p>
                  <span>
                    Upon receiving your request, we will need to verify your
                    identity to determine you are the same person about whom we
                    have the information in our system. We will only use
                    personal information provided in your request to verify your
                    identity or authority to make the request. However, if we
                    cannot verify your identity from the information already
                    maintained by us, we may request that you provide additional
                    information for the purposes of verifying your identity and
                    for security or fraud-prevention purposes.
                  </span>
                </p>
                <p>
                  <span>
                    If you submit the request through an authorized agent, we
                    may need to collect additional information to verify your
                    identity before processing your request and the agent will
                    need to provide a written and signed permission from you to
                    submit such request on your behalf.
                  </span>
                </p>
                <p>
                  <strong>
                    <span>Appeals</span>
                  </strong>
                </p>
                <p>
                  <span>
                    Under certain US state data protection laws, if we decline
                    to take action regarding your request, you may appeal our
                    decision by emailing us at support@skipapp.com. We will
                    inform you in writing of any action taken or not taken in
                    response to the appeal, including a written explanation of
                    the reasons for the decisions. If your appeal is denied, you
                    may submit a complaint to your state attorney general.
                  </span>
                </p>

                <p id="list-12">
                  <strong>
                    <span>10. DO WE MAKE UPDATES TO THIS NOTICE?</span>
                  </strong>
                </p>

                <p>
                  <strong>
                    <em>
                      <span>In Short:&nbsp;</span>
                    </em>
                  </strong>
                  <em>
                    <span>
                      Yes, we will update this notice as necessary to stay
                      compliant with relevant laws.
                    </span>
                  </em>
                </p>
                <p>
                  <span>
                    We may update this Privacy Notice from time to time. The
                    updated version will be indicated by an updated "Revised"
                    date at the top of this Privacy Notice. If we make material
                    changes to this Privacy Notice, we may notify you either by
                    prominently posting a notice of such changes or by directly
                    sending you a notification. We encourage you to review this
                    Privacy Notice frequently to be informed of how we are
                    protecting your information.
                  </span>
                </p>

                <p id="list-13">
                  <strong>
                    <span>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                  </strong>
                </p>

                <p>
                  <span>
                    If you have questions or comments about this notice, you may
                    contact us by post at:
                  </span>
                </p>
                <p>
                  <span>Skip, LLC</span>
                </p>
                <span>CA</span>
                <p>
                  <span>United States</span>
                </p>

                <p id="list-14">
                  <strong>
                    <span>
                      12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                      COLLECT FROM YOU?
                    </span>
                  </strong>
                </p>

                <p>
                  <span>
                    Based on the applicable laws of your country or state of
                    residence in the US, you may have the right to request
                    access to the personal information we collect from you,
                    details about how we have processed it, correct
                    inaccuracies, or delete your personal information. You may
                    also have the right to withdraw your consent to our
                    processing of your personal information. These rights may be
                    limited in some circumstances by applicable law. To request
                    to review, update, or delete your personal information,
                    please fill out and submit a data subject access request.
                  </span>
                </p>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
