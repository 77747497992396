import { Grid, Typography, Box, Container } from "@mui/material";
import { colors } from "../style/colors";
import Footer from "../Components/Footer";
import SkipList from "../Components/SkipList";
import Header from "../Components/Header";

const BusinessCard = () => {
  return (
    <>
      <Header />
      {/* Explore Restaurants */}
      <Box component="section" sx={{ py: { md: 10, xs: 6 } }}>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs="12" md="7" sx={{ pb: { md: 6, xs: 4 } }}>
              <Typography
                variant="h2"
                pb="12px"
                color={colors.themeText.textBlack}
              >
                Explore Restaurants
              </Typography>
              <Typography
                variant="body1"
                pr="24px"
                color={colors.themeText.TextGray}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard text ever
                since the 1500s.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <SkipList />
      </Box>

      {/* Footer */}
      <Footer />
    </>
  );
};
export default BusinessCard;
