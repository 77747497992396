import React from "react";
import "../src/App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BusinessCard from "./Pages/Index";
import StripeProvider from "./StripeContext";
import TermsAndCondition from "./Pages/TermsAndCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import CoockiePilicy from "./Pages/CoockiePilicy";
const App = () => {
  return (
    <>
      <StripeProvider>
        <Router>
          <Routes>
            <Route path="/" element={<BusinessCard />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndCondition />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CoockiePilicy />} />
          </Routes>
        </Router>
      </StripeProvider>
    </>
  );
};

export default App;
