import React from "react";
import { colors } from "../style/colors";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import HeroBanner from "../assets/images/HeroBanner.png";
import {
  Backdrop,
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";

const Header = () => {
  return (
    <Box
      component="section"
      sx={{
        bgcolor: colors.themeBg.bgBlack,
        position: "relative",
        backgroundImage: `url('${HeroBanner}')`,
        backgroundPosition: "top center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container sx={{ position: "relative", zIndex: 1 }} maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          py={{ sm: 3, xs: 1 }}
        >
          <Grid item xs="auto">
            <Link href="/">
              <Logo />
            </Link>
          </Grid>
          <Grid item xs="auto">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                variant="buttonGhostTonal"
                sx={{ px: 3, py: 1.5, mr: { md: 3, xs: 1 } }}
              >
                Request Restaurant{" "}
              </Button>
              <Button variant="contained" sx={{ px: 3, py: 1.5 }}>
                Become lister
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Content */}
        <Grid
          container
          justifyContent="center"
          pt={{ sm: 7, xs: 4 }}
          pb={{ sm: 10, xs: 6 }}
        >
          <Grid item xs="12" md="6">
            <Typography
              variant="h1"
              mb={2}
              color={colors.themeText.TextWhite}
              textAlign="center"
            >
              Skip the waiting line with waiting buddy
            </Typography>
            <Typography
              variant="body2"
              fontWeight="normal"
              color={colors.themeText.TextWhite}
              textAlign="center"
              sx={{ opacity: 0.9 }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard text ever
              since the 1500s.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Backdrop
        open
        sx={{
          position: "absolute",
          inset: 0,
          bgcolor: colors.themeBg.bgBlack,
          opacity: "0.9 !important",
          filter: "blur(216)",
        }}
      ></Backdrop>
    </Box>
  );
};

export default Header;
