import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { colors } from "../style/colors";

const SideContent = () => {
  return (
    <Grid item md="4" xs="12" pl={{ md: "55px" }} pt={{ xs: "30px", md: 0 }}>
      <Box
        sx={{
          borderRadius: "16px",
          boxShadow: "0px 3px 15px #ebebeb",
          position: "sticky",
          top: "50px",
        }}
        p="16px 24px"
      >
        <Typography variant="h4" pb="24px" color={colors.themeText.textBlack}>
          Notes from the Business
        </Typography>
        <Typography
          variant="body1420"
          pb="16px"
          display="block"
          color={colors.themeText.textBlack2}
        >
          Currently serving restaurants below that DO NOT take reservations
        </Typography>
        <Typography
          variant="body1420"
          pb="16px"
          display="block"
          color={colors.themeText.textBlack2}
        >
          <b>Step 1:</b> Select Skip type
        </Typography>
        <Typography
          variant="body1420"
          pb="16px"
          display="block"
          color={colors.themeText.textBlack2}
        >
          <b>Step 2:</b> Enter party details
        </Typography>
        <Typography
          variant="body1420"
          pb="16px"
          display="block"
          color={colors.themeText.textBlack2}
        >
          <b>Step 3:</b> If using “Skip Now” pay and be added to live waitlist.
          If using “Skip Reserve” pay and have your party added to waitlist on
          selected date and time.
        </Typography>
        <Typography
          variant="body1420"
          pb="16px"
          display="block"
          color={colors.themeText.textBlack2}
        >
          <b>Step 4:</b> You will receive picture confirmation via text of your
          added party
        </Typography>
      </Box>
    </Grid>
  );
};

export default SideContent;
