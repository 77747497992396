import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";

const typographyOptions = ({ theme }) => {
  return {
    fontFamily: "Poppins, sans-serif",
    lineHeight: "1.5",
    color: colors.basics.black,
    h1: {
      fontFamily: "K2D, sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: "48px",
      '@media only screen and (max-width: 767px)' : {
        fontSize: "30px",
        lineHeight: "36px",
      }
    },
    h2: {
      fontFamily: "K2D, sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "42px",
      '@media only screen and (max-width: 767px)' : {
        fontSize: "24px",
        lineHeight: "30px",
      }
    },
    h4: {
      fontFamily: "K2D, sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "26px",
    },

    h6: {
      fontFamily: "K2D, sans-serif",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "20px",
    },

    // Body Fonts
    body1: {
      fontFamily: "Poppins, sans-serif",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "24px",
    },
    body2: {
      fontFamily: "Poppins, sans-serif",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "24px",
    },

    body1420: {
      fontFamily: "Poppins, sans-serif",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "18px",
    },

    subtitle1: {
      fontFamily: "Poppins, sans-serif",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: "-0.02em",
      textTransform: "capitalize",
      color: colors.basics.black,
    },
    subtitle2: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "18px",
      fontWeight: "600",
      letterSpacing: "0em",
      textAlign: "left",
      color: colors.basics.black,
    },
  };
};

const componentOptions = {
  MuiButton: {
    defaultProps: {
      variant: "contained",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        boxSizing: "border-box",
        borderRadius: 40,
        fontFamily: "Poppins, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        alignItems: "center",
        textTransform: "capitalize",
        boxShadow: "none !important",
        transition: "all .5s ease",
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
          padding: "6px 16px",
        },
      }),
      contained: ({ theme }) => ({       
        color: colors.themeText.textBlack,
        background: colors.themeBg.bgPrimary,
        "&:hover": {
          background: colors.themeBg.bgPrimaryHover,
        },
        "&:disabled": {
          background: colors.basics.theme,
          color: colors.themeText.TextWhite,
        },
      }),
      // ButtonBlack
      buttonBlack: ({ theme }) => ({       
        color: colors.themeText.TextWhite,
        background: colors.themeBg.bgBlack,
        "&:hover": {
          background: colors.themeBg.bgPrimary,
        },
        "&:disabled": {
          background: colors.basics.theme,
          color: colors.themeText.TextWhite,
        },
      }),
       // ButtonWhite
       buttonWhite: ({ theme }) => ({       
        color: colors.themeText.textBlack,
        background: colors.themeBg.bgWhite,
        "&:hover": {
          color: colors.themeText.TextWhite,
          background: colors.themeBg.bgBlack,
        },
        "&:disabled": {
          background: colors.basics.theme,
          color: colors.themeText.TextWhite,
        },
      }),
      // buttonGhost
      buttonGhost: ({ theme }) => ({        
        color: colors.themeText.textBlack,
        background: "transperent",
        "&:hover": {
          color: colors.themeText.TextWhite,
          background: colors.themeBg.bgBlack,
        },
        "&:disabled": {
          background: colors.basics.theme,
          color: colors.themeText.TextWhite,
        },
      }),
      // buttonGhostTonal
      buttonGhostTonal: ({ theme }) => ({
        color: colors.themeText.TextWhite,
        background: "transperent",
        "&:hover": {
          color: colors.themeText.textBlack,
          background: colors.themeBg.bgWhite,
        },
        "&:disabled": {
          background: colors.basics.theme,
          color: colors.themeText.TextWhite,
        },
      }),


    }
  }, 

};

const theme = createTheme({
  typography: typographyOptions,
  components: componentOptions,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 992,
      lg: 1024,
      xl: 1264,
    },
  },
  palette: {
    primary: {
      main: "#fff",
    },
  }, 
});

export default theme;